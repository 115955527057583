<template>
  <v-menu v-model="open" offset-y :nudge-width="200" z-index="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        :on="on"
        v-bind="attrs"
        @click.stop="open = !open"
        :loading="pending"
      >
        <v-badge
          :value="alertas.nAlertas"
          :content="alertas.nAlertas <= 100 ? alertas.nAlertas : '100+'"
          v
          color="error darken-1"
          overlap
        >
          <v-icon ref="bell">mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card min-width="400">
      <div class="d-flex align-center">
        <v-subheader>Alertas</v-subheader>
        <v-spacer />
        <v-btn small color="primary" @click.stop="refresh">
          <v-icon left>mdi-reload</v-icon>
          Recargar</v-btn
        >
      </div>
      <v-divider></v-divider>
      <v-list nav dense class="overflow-y-auto" style="max-height: 400px">
        <v-list-item
          dense
          v-for="(alerta, i) in alertas.alertas"
          :key="i"
          :to="`/detalles_estudio?idEstudio=${alerta.idEstudio}`"
          exact
          :color="alerta.color"
        >
          <v-list-item-content>
            <v-list-item-title> {{ alerta.textoAlerta }} </v-list-item-title>
            <v-list-item-subtitle class="d-flex flex-wrap">
              {{ alerta.nombreProyecto }}
              <span class="ml-auto" v-if="idUsuario != alerta.idUsuario">{{
                alerta.comercial
              }}</span>
              <span class="flex-grow" style="width: 100%">{{
                alerta.sugerencia
              }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import { ref, watch } from "vue";
import axios from "@/utils/axiosWrapper";
import { useIntervalFn } from "@vueuse/core";
import store from "@/store/index";

const idUsuario = store.getters.getTokenPayload.idUsuario;

const open = ref(false);
const bell = ref(null);

const prev = ref(0);

const { data : alertas, pending, refresh } = axios(
  `${process.env.VUE_APP_API_URL}/alertas`,
  {
    default: () => ({
      alertas: [],
      nAlertas: 0,
    }),
  }
);

function animationAndSound() {
  bell.value.$el.classList.remove("animation");
  bell.value.$el.classList.add("animation");

  // const audioCtx = new AudioContext();
  // const audio = new Audio(require("@/assets/bellring.mp3"));
  // audio.crossOrigin = "anonymous";
  // const source = audioCtx.createMediaElementSource(audio);
  // source.connect(audioCtx.destination);
  // audio.play();
}

useIntervalFn(() => refresh, 5 * 60 * 1000);

watch(
  () => alertas.nAlertas,
  (v) => {
    if (alertas.nAlertas > prev.value) animationAndSound();

    prev.value = alertas.nAlertas;
  }
);
</script>

<style scoped lang="scss">
.clip {
  text-align: justify;
  max-height: 120px;
  max-width: 400px;
  overflow-y: hidden;
}

.animation {
  animation: ring 4s 0.7s ease-in-out;
  transform-origin: 50% 4px;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
